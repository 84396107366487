import React, { useState, useEffect } from 'react';
import { Modal, Input, message, Form, Button } from 'antd';
import axios from 'axios';
import './index.less';
import './../../../App.css';
import InquiryModal from '../../../compontents/inquiryModal';

const { TextArea } = Input;

function Header(props) {

  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false)
  const [isShowC, setIsShowC] = useState(false);
  const [isShowLease, setIsShowLease] = useState(false);
  const [css1, setCss1] = useState({});
  const [css2, setCss2] = useState({});
  const [css3, setCss3] = useState({});
  const [css4, setCss4] = useState({});
  const [css5, setCss5] = useState({});
  const [css6, setCss6] = useState({});
  const [font1, setFont1] = useState("");
  const [font2, setFont2] = useState("");
  const [font3, setFont3] = useState("");
  const [font4, setFont4] = useState("");
  const [font5, setFont5] = useState("");
  const [font6, setFont6] = useState("");

  useEffect(() => getNews(), [])

  const getNews = (category, pageSize) => axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'GET',
    url: `/consumer-zhifubao/news/center?newsCategory=0&pageSize=10&pageNumber=1`,
    responseType: 'json',
  }).then((res) => {
    console.log(res);
    if (res.data.code === 1) {
      console.log(res.data.data.opeNews);
    }
  })


  const submitForm = (data) => axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    url: '/consumer-zhifubao/aliPay/user/userEnterpriseLeaseApply/insertRecord',
    responseType: 'json',
    data
  })

  const onFinish = (values) => {
    const categoryNames = font1 + font2 + font3 + font4 + font5 + font6;
    submitForm({
      categoryNames: categoryNames || undefined,
      customerRequirement: values?.customerRequirement ? values.customerRequirement : '',
      telephone: values?.telephone ? values.telephone : undefined,
      consultType: 2,
      consultSource: 1,
    }).then(res => {
      if (res?.data?.code === 1) {
        canceLease()
        message.success(res.data.msg);
      } else {
        values?.telephone ? message.error(res.data.msg) : message.error('请填写联系电话');
      }
    });
  };

  const changeCss1 = () => {
    if (css1.background === undefined) {
      setCss1({
        background: "#FEF7F6",
        borderRadius: "10px",
        border: "2px solid #ED0000",
      })
      setFont1("笔记本,")
    } else {
      setCss1({})
      setFont1("")
    }
  }

  const changeCss2 = () => {
    if (css2.background === undefined) {
      setCss2({
        background: "#FEF7F6",
        borderRadius: "10px",
        border: "2px solid #ED0000",
      })
      setFont2("台式电脑,")
    } else {
      setCss2({})
      setFont2("")
    }
  }

  const changeCss3 = () => {
    if (css3.background === undefined) {
      setCss3({
        background: "#FEF7F6",
        borderRadius: "10px",
        border: "2px solid #ED0000",
      })
      setFont3("打印机,")
    } else {
      setCss3({})
      setFont3("")
    }
  }

  const changeCss4 = () => {
    if (css4.background === undefined) {
      setCss4({
        background: "#FEF7F6",
        borderRadius: "10px",
        border: "2px solid #ED0000",
      })
      setFont4("手机,")
    } else {
      setCss4({})
      setFont4("")
    }
  }

  const changeCss5 = () => {
    if (css5.background === undefined) {
      setCss5({
        background: "#FEF7F6",
        borderRadius: "10px",
        border: "2px solid #ED0000",
      })
      setFont5("投影仪,")
    } else {
      setCss5({})
      setFont5("")
    }
  }

  const changeCss6 = () => {
    if (css6.background === undefined) {
      setCss6({
        background: "#FEF7F6",
        borderRadius: "10px",
        border: "2px solid #ED0000",
      })
      setFont6("其他,")
    } else {
      setCss6({})
      setFont6("")
    }
  }

  const changePages = (num) => {
    window.location.href = `${window.location.origin}${window.location.pathname}?flag=${num}`
  }

  const showMoal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const showC = () => {
    setIsShowC(true)
  }

  const cancelC = () => {
    setIsShowC(false)
  }

  const showLease = () => {
    setIsShowLease(true)
  }

  // const handleShowModal = () => {
  //   setIsModalShow(true)
  // }

  const handleCancelModal = () => {
    setIsModalShow(false)
  }

  const canceLease = () => {
    setFont1("");
    setFont2("");
    setFont3("");
    setFont4("");
    setFont5("");
    setFont6("");
    setCss1({});
    setCss2({});
    setCss3({});
    setCss4({});
    setCss5({});
    setCss6({});
    form.resetFields()
    setIsShowLease(false)
  }

  return (
    <div className="navContainer">
      <nav className="nav-div">
        <span className="nav-icon"></span>
        {/* <span className="nanav-icon-fontme">来用洞察</span> */}
        <span className="nanav-icon-fontme">杭州脚本信息技术有限公司</span>
        {/* <span className="nav-icon-font">领先的数字化租赁交易服务提供商</span> */}
        <span className="nav-font" onClick={() => changePages(1)}>首页<div className="border-line"></div></span>
        <span className="nav-fonts" onClick={showC}>C端服务<div className="border-line" style={{ width: 66 }}></div></span>
        <div style={{ display: "inline-block" }} className="hover-box">
          <span className="nav-fonts-hover">B端服务(云服务)<div className="border-line"></div></span>
          <div className="nav-title">
            <div className="title-left">
              <div className="title">商家产品</div>
              <a className="content" style={{ marginTop: 12 }} href={window.location.origin + window.location.pathname + '?flag=2'} target="_blank" rel='noreferrer'>商家入驻</a>
              <a className="content" href={window.location.origin + window.location.pathname + '?flag=3'} target="_blank" rel='noreferrer'>租赁小程序</a>
              <a className="content" href={window.location.origin + window.location.pathname + '?flag=4'} target="_blank" rel='noreferrer'>开放平台</a>
              <a className="content" href={window.location.origin + window.location.pathname + '?flag=5'} target="_blank" rel='noreferrer'>锁机</a>
            </div>
            <div className="title-right">
              <div className="title">
                适用场景/行业
              </div>
              <div className="content">
                <div className="left">
                  <span className="fonts">零售门店</span>
                  <span className="fonts">微信私域</span>
                  <span className="fonts">电动车</span>
                  <span className="fonts">景区酒店</span>
                </div>
                <div className="center">
                  <span className="fonts">手机数码</span>
                  <span className="fonts">母婴图书</span>
                  <span className="fonts">支付宝生态</span>
                  <span className="fonts"> 自助设备</span>
                </div>
                <div className="right">
                  <span className="fonts">家居家电</span>
                  <span className="fonts">游戏电玩</span>
                  <span className="fonts">企业办公</span>
                  <span className="fonts" style={{ color: '#DFDFDF' }}>更多敬请期待</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          href="https://apple.huizustore.com/login"
          target="_blank"
          rel="noreferrer"
          className="nav-fonts"
        >
          惠租锁机
        </a>
        {/* <span className="nav-fonts" onClick={handleShowModal}>惠租锁机<div className="border-line"></div></span> */}
        <span className="nav-fonts" onClick={showLease}>企业租赁<div className="border-line"></div></span>
        <span className="nav-fonts" onClick={showMoal}>风控报告<div className="border-line"></div></span>
        <span className="nav-fonts" onClick={() => changePages(6)}>新闻中心<div className="border-line"></div></span>
        <span className="nav-fonts" onClick={() => changePages(7)}>关于我们<div className="border-line"></div></span>
        <Button className="nav-button">
          <a
            href="https://business.huizustore.com/#/business/user/login"
            target="_blank"
            rel="noreferrer"
          >
            进入商家后台
          </a>
        </Button>
      </nav>
      <InquiryModal visible={isModalShow} handleCancel={handleCancelModal} />
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modalReport"
        getContainer={false}
        // mask={true}
        width={600}
      >
        <div className="title">惠租风控报告</div>
        <div className="icon">
          <div className="icon-pic"></div>
          <div className="icon-title">微信扫一扫</div>
        </div>
        <div className="icon-tip">
          基于神经网络算法的模型<br />
          为你的经营保驾护航
        </div>
      </Modal>
      <Modal
        visible={isShowC}
        onCancel={cancelC}
        footer={null}
        className="modalRevice"
        getContainer={false}
        width={930}
      >
        <div className="background">
          <div className="title">C端服务</div>
          <div className="icons">
            <div className="icon-left">
              <div className="left-pic"></div>
              <div className="left-tip">支付宝小程序</div>
            </div>
            <div className="icon-center">
              <div className="center-pic"></div>
              <div className="center-tip">
                Android客户端
              </div>
            </div>
            <div className="icon-right">
              <div className="right-pic"></div>
              <div className="right-tip">
                IOS客户端
                <div className="tip-small">(仅支持微信扫一扫)</div></div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={isShowLease}
        onCancel={canceLease}
        footer={null}
        className="modalLease"
        getContainer={false}
        width={660}
      >
        <div className="modalContainer">
          <Form name="basic" onFinish={onFinish} form={form}>
            <div className="title">企业租赁</div>
            <div className="title-top">您意向租赁的产品（可多选）</div>
            <div className="icons">
              <div className="iconTop">
                <div className="left" style={css1} onClick={changeCss1}>
                  <div className="left-pic"></div>
                  <div className="left-font">笔记本</div>
                </div>
                <div className="center" style={css2} onClick={changeCss2}>
                  <div className="center-pic"></div>
                  <div className="center-font">台式电脑</div>
                </div>
                <div className="right" style={css3} onClick={changeCss3}>
                  <div className="right-pic"></div>
                  <div className="right-font">打印机</div>
                </div>
              </div>
              <div className="iconBottom">
                <div className="left" style={css4} onClick={changeCss4}>
                  <div className="left-pic"></div>
                  <div className="left-font">手机</div>
                </div>
                <div className="center" style={css5} onClick={changeCss5}>
                  <div className="center-pic"></div>
                  <div className="center-font">投影仪</div>
                </div>
                <div className="right" style={css6} onClick={changeCss6}>
                  <div className="right-pic"></div>
                  <div className="right-font">其他</div>
                </div>
              </div>
            </div>
            <div className="title-center">其它要求（选填，可不填）</div>
            <div className="textArea">
              <Form.Item name="customerRequirement">
                <TextArea
                  className="TextArea"
                  showCount={true}
                  bordered={false}
                  maxLength={200}
                  placeholder="其他要求，例如新旧程度等；推荐方案免费咨询，不收取任何费用，快速配置最合适的配置方案。"
                />
              </Form.Item>
            </div>
            <div className="form">
              <div className="title-bottom">联系电话：</div>
              <Form.Item name="telephone">
                <div className="input">
                  <Input type="text" className="input-child" placeholder="请输入手机号" />
                </div>
              </Form.Item>
            </div>
            <button className="button" htmlType="submit">提交委托</button>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default Header;
